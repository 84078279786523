<template>
    <div>
        <el-dialog
            :title="dialogFormTitle"
            :visible.sync="dialogFormVisible"
            :append-to-body="true"
            width="30%"
            :center="true"
            top="20vh"
            :close-on-click-modal="false"
            :before-close="() => closeBudgetDialog()"
            v-loading.fullscreen.lock="login_loading"
        >
            <div>
                <el-form
                    :model="Budget"
                    :rules="rules"
                    ref="BudgetEditForm"
                    label-width="100px"
                    size="small"
                >
                    <div v-if="show_type">
                        <el-form-item label="企业" prop="with_enterprise_id">
                            <el-select
                                v-model="Budget.with_enterprise_id"
                                placeholder="选择企业"
                                class="with_enterprise_idc"
                                filterable
                                clearable
                            >
                                <el-option
                                    v-for="enterprise in AllEnterprise"
                                    :key="enterprise.id"
                                    :label="enterprise.name"
                                    :value="enterprise.id"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="年份" prop="year">
                            <el-select
                                v-model="Budget.year"
                                placeholder="选择年份"
                                class="yearc"
                                filterable
                                clearable
                            >
                                <el-option
                                    v-for="year in AllYear"
                                    :key="year.id"
                                    :label="year.name"
                                    :value="year.id"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="季度" prop="quarter">
                            <el-select
                                v-model="Budget.quarter"
                                placeholder="选择季度"
                                class="quarterc"
                                filterable
                                clearable
                            >
                                <el-option
                                    v-for="v in AllQuarter"
                                    :key="v.id"
                                    :label="v.name"
                                    :value="v.id"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="申请金额" prop="money">
                            <el-input-number
                                v-model="Budget.money"
                                :min="0"
                                :step="1"
                                :precision="2"
                                :step-strictly="false"
                                size="small"
                                class="input_number"
                            ></el-input-number>
                        </el-form-item>
                    </div>
                    <div v-else>
                        <el-descriptions
                            class="margin-top"
                            :column="1"
                            :labelStyle="{'width':'120px','text-align':'center'}"
                            :contentStyle="{'text-align': 'center'}"
                            border
                        >
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>企业名称</span>
                                </template>
                                <span>{{Budget.with_enterprise_name}}</span>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>年份</span>
                                </template>
                                <span>{{Budget.year + '年'}}</span>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>季度</span>
                                </template>
                                <span>{{Budget.quarter_name}}</span>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>预算金额</span>
                                </template>
                                <span>{{Budget.money | diy_money}}</span>
                            </el-descriptions-item>
                        </el-descriptions>
                    </div>
                </el-form>
            </div>
            <div slot="footer" class="dialog-footer">
                <div v-if="show_type===0">
                    <el-button
                        v-if="$_has(edit_auth)"
                        type="primary"
                        plain
                        @click="$emit('show_edit')"
                        >修改</el-button
                    >
                    <el-button
                        v-if="$_has(del_auth)"
                        type="danger"
                        plain
                        @click="BudgetDel('BudgetEditForm')"
                        >删除</el-button
                    >
                    <el-button
                        plain
                        @click="closeBudgetDialog()"
                        >关闭</el-button
                    >
                </div>
                <div v-else-if="show_type===1">
                    <el-button
                        type="success"
                        plain
                        @click="submitInsert('BudgetEditForm')"
                        >确定</el-button
                    >
                    <el-button
                        type="warning"
                        plain
                        @click="resetForm()"
                        >重置</el-button
                    >
                    <el-button
                        plain
                        @click="closeBudgetDialog()"
                        >关闭</el-button
                    >
                </div>
                <div v-else>
                    <el-button
                        type="success"
                        plain
                        @click="submitEdit('BudgetEditForm')"
                        >确定修改</el-button
                    >
                    <el-button
                        plain
                        @click="closeBudgetDialog()"
                        >取消操作</el-button
                    >
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { budget_add_request,budget_del_request,budget_edit_request } from '@/network/finance/Budget.js'
import { fomatFloat,thousandBitSeparator } from '@/assets/js/common.js'

export default {
    name: 'BudgetEditComponent',
    data() {
        return {
            login_loading: false,
            Budget: {
                with_enterprise_id: '',
                year: '',
                quarter: '',
                money: ''
            },
            rules: {
                with_enterprise_id: [
                    {
                        required: true,
                        message: '缺少企业',
                        trigger: 'blur',
                    }
                ],
                year: [
                    {
                        required: true,
                        message: '缺少年份',
                        trigger: 'blur',
                    }
                ],
                quarter: [
                    {
                        required: true,
                        message: '缺少季度',
                        trigger: 'blur',
                    }
                ]
            }
        }
    },
    computed: {
        dialogFormTitle() {
            if(this.show_type === 0) {
                return '预算详情'
            } else if (this.show_type === 1) {
                return '添加预算'
            } else {
                return '修改预算'
            }
        }
    },
    methods: {
        closeBudgetDialog() {
            this.resetForm()
            this.$emit('exitBudgetDialog')
        },
        submitInsert(formName) {
            this.$refs[formName].validate((valid) => {
                if (!valid) {
                    return
                }
                this.BudgetInsert(formName)
            })
        },
        BudgetInsert(formName) {
            this.login_loading = true
            budget_add_request(this.Budget)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.$message.success('添加成功!')
                        this.$emit('exitBudgetDialog')
                        this.$emit('search')
                        this.$emit('details_row',s.result.id*1)
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        },
        resetForm() {
            this.Budget = {
                with_enterprise_id: '',
                year: '',
                quarter: '',
                money: ''
            }
        },
        submitEdit(formName) {
            this.$refs[formName].validate((valid) => {
                if (!valid) {
                    return
                }
                this.BudgetEdit(formName)
            })
        },
        BudgetEdit(formName) {
            this.login_loading = true
            this.Budget.id = this.id
            console.log(this.Budget);
            budget_edit_request(this.Budget)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.$message.success('修改成功!')
                        this.$emit('exitBudgetDialog')
                        this.$emit('search')
                        this.$emit('details_row',this.Budget.id)
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        },
        BudgetDel(formName) {
            this.$confirm(
                '是否确定删除？（一经删除，将无法再进行恢复，请谨慎操作！）',
                '温馨提示',
                {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }
            )
                .then(() => {
                    budget_del_request(this.id)
                        .then((s) => {
                            if (s.status === 0) {
                                this.$message.success('删除成功!')
                                this.$emit('search')
                            } else {
                                this.$message.error(s.msg)
                            }
                        })
                        .catch((err) => {
                            this.$message.error(err)
                        })
                        .finally(() => this.closeBudgetDialog())
                })
                .catch((err) => {})
        },
    },
    filters: {
        diy_money(v) {
            return thousandBitSeparator(fomatFloat(v))
        }
    },
    props: {
        dialogFormVisible: Boolean,
        id: {
            type: Number,
            default() {
                return 0
            },
        },
        Allstaff: {
            type: Array,
            default() {
                return []
            },
        },
        AllYear: {
            type: Array,
            default() {
                return []
            },
        },
        AllQuarter: {
            type: Array,
            default() {
                return []
            },
        },
        AllEnterprise: {
            type: Array,
            default() {
                return []
            },
        },
        budget_details_data: {
            type: Object,
            default() {
                return {}
            },
        },
        show_type: {
            type:Number,
            default() {
                return 0
            },
        },
        edit_auth: String,
        del_auth: String
    },
    created() {},
    destoryed() {},
    mounted() {},
    updated() {},
    components: {},
    watch: {
        budget_details_data(newVal) {
            this.Budget = newVal
        },
    },
}
</script>

<style lang='less'>
.staff_idc,.yearc,.quarterc,.with_enterprise_idc,.input_number {
    width: 100%;
}
</style>
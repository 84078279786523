<template>
    <div v-loading.fullscreen.lock="loading">
        <budget-search-bar-component
            add_auth="新增预算"
            :AllEnterprise="AllEnterprise"
            :AllYear="AllYear"
            :AllQuarter="AllQuarter"
            @search="get_budget_index"
            @addTeam="addTeam"
        ></budget-search-bar-component>
        <common-table-component
            details_auth="预算详情"
            :col_arr="fcol_arr"
            :table_data="ftable_data"
            @details_row="details_budget"
        ></common-table-component>
        <common-page-component
            :current_page="cond.page"
            :page_size="cond.limit"
            :total="ftotal"
            @search="get_budget_index"
        ></common-page-component>
        <budget-edit-component
            edit_auth="修改预算"
            del_auth="删除预算"
            :id="fid"
            :show_type="edit_type"
            :dialogFormVisible="fdialogFormVisible"
            :budget_details_data="fbudget_details_data"
            :AllEnterprise="AllEnterprise"
            :AllYear="AllYear"
            :AllQuarter="AllQuarter"
            @exitBudgetDialog="dialogExit"
            @search="get_budget_index"
            @show_edit="show_edit"
            @details_row="details_budget"
        ></budget-edit-component>
    </div>
</template>

<script>
import { budget_index_request,budget_details_request } from '@/network/finance/Budget.js'
import { enterprise_list_request } from '@/network/list.js'
import { thousandBitSeparator,fomatFloat} from '@/assets/js/common.js'

import BudgetSearchBarComponent from '@/components/finance/Budget/BudgetSearchBarComponent'
import CommonTableComponent from '@/components/common/CommonTableComponent'
import CommonPageComponent from '@/components/common/CommonPageComponent'
import BudgetEditComponent from '@/components/finance/Budget/BudgetEditComponent'

export default {
    name: 'BudgetView',
    data() {
        return {
            fid: 0,
            edit_type: 0,
            fdialogFormVisible: false,
            loading: false,
            ftotal: 0,
            cond: {
                with_enterprise_id: '',
                year: '',
                quarter: '',
                page: 1,
                limit: 10,
            },
            ftable_data: [],
            fcol_arr: [
                {
                    prop: 'with_enterprise_name',
                    label: '企业名称',
                    minWidth: '260px'
                },
                {
                    prop: 'year',
                    label: '年份',
                    minWidth: '100px'
                },
                {
                    prop: 'quarter_name',
                    label: '季度',
                    minWidth: '100px'
                },
                {
                    prop: 'money',
                    label: '预算金额',
                    minWidth: '150px',
                    editdata(row) {
                        return thousandBitSeparator(fomatFloat(row['money']))
                    }
                },
                {
                    prop: 'create_staff_name',
                    label: '创建人',
                    minWidth: '100px'
                },
                {
                    prop: 'create_time',
                    label: '创建时间',
                    minWidth: '180px'
                }
            ],
            fbudget_details_data: {},
            AllEnterprise: [],
            AllYear: [{id:2023,name:'2023年'},{id:2024,name:'2024年'},{id:2025,name:'2025年'},{id:2026,name:'2026年'},{id:2027,name:'2027年'},{id:2028,name:'2028年'},{id:2029,name:'2029年'},{id:2030,name:'2030年'}],
            AllQuarter :[{id:1,name:'第一季度'},{id:2,name:'第二季度'},{id:3,name:'第三季度'},{id:4,name:'第四季度'}]
        }
    },
    computed: {},
    methods: {
        addTeam() {
            this.edit_type = 1
            this.fdialogFormVisible = true
        },
        show_edit() {
            this.edit_type = 2
        },
        get_budget_index(param = {}) {
            this.loading = true
            this.cond.with_enterprise_id = param.with_enterprise_id ?? this.cond.with_enterprise_id
            this.cond.year = param.year ?? this.cond.year
            this.cond.quarter = param.quarter ?? this.cond.quarter
            this.cond.page = param.page ?? this.cond.page
            if (param.limit) {
                this.cond.limit = param.limit
                this.cond.page = 1
            }

            budget_index_request(this.cond)
                .then((s) => {
                    this.loading = false
                    if (s.status === 0) {
                        this.ftable_data = s.result.data
                        this.ftotal = s.result.total
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })
        },
        details_budget(id) {
            this.fid = id
            this.fdialogFormVisible = true
            budget_details_request(id)
                .then((s) => {
                    if (s.status === 0) {
                        this.fbudget_details_data = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        dialogExit() {
            this.fdialogFormVisible = false
            this.fid = 0
            this.edit_type = 0
        },
        get_all_enterprise() {
            this.loading = true
            enterprise_list_request()
                .then((s) => {
                    this.loading = false
                    if (s.status === 0) {
                        this.AllEnterprise = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })
        }
    },
    filters: {},
    props: {},
    created() {
        this.get_budget_index()
        this.get_all_enterprise()
    },
    destoryed() {},
    mounted() {},
    updated() {},
    components: {
        BudgetSearchBarComponent,
        CommonTableComponent,
        CommonPageComponent,
        BudgetEditComponent,
    },
    watch: {},
}
</script>

<style lang='less'></style>
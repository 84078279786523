<template>
    <el-card class="box-card">
        <el-form
            ref="searchform"
            :inline="true"
            :model="form"
            @submit.native.prevent
            size="medium"
        >
            <el-form-item label="企业" prop="with_enterprise_id">
                <el-select
                    v-model="form.with_enterprise_id"
                    placeholder="选择企业"
                    class="with_enterprise_idc"
                    filterable
                    clearable
                >
                    <el-option
                        v-for="enterprise in AllEnterprise"
                        :key="enterprise.id"
                        :label="enterprise.name"
                        :value="enterprise.id"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="年份" prop="year">
                <el-select
                    v-model="form.year"
                    placeholder="选择年份"
                    class="yearc"
                    filterable
                    clearable
                >
                    <el-option
                        v-for="year in AllYear"
                        :key="year.id"
                        :label="year.name"
                        :value="year.id"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="季度" prop="quarter">
                <el-select
                    v-model="form.quarter"
                    placeholder="选择季度"
                    class="quarterc"
                    filterable
                    clearable
                >
                    <el-option
                        v-for="v in AllQuarter"
                        :key="v.id"
                        :label="v.name"
                        :value="v.id"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" plain @click="search">搜索</el-button>
                <el-button type="warning" plain @click="resetbtn('searchform')"
                    >重置</el-button
                >
            </el-form-item>
            <el-button
                v-if="$_has(add_auth)"
                type="success"
                plain
                round
                @click="addTeam"
                class="temp_add"
                size="medium"
                >添加预算</el-button
            >
        </el-form>
    </el-card>
</template>

<script>
export default {
    name: 'BudgetSearchBarComponent',
    data() {
        return {
            fdialogFormVisible: false,
            form: {
                with_enterprise_id: '',
                year: '',
                quarter: '',
                page: 1,
            },
        }
    },
    computed: {},
    methods: {
        search() {
            this.$emit('search', this.form)
        },
        resetbtn(f) {
            this.$refs[f].resetFields()
        },
        addTeam() {
            this.$emit('addTeam')
        },
    },
    filters: {},
    props: {
        add_auth: String,
        AllEnterprise: Array,
        AllYear: Array,
        AllQuarter: Array
    },
    created() {},
    destoryed() {},
    mounted() {},
    updated() {},
    components: {},
    watch: {},
}
</script>

<style>
.temp_add {
    float: right;
    margin-bottom: 10px;
}
</style>
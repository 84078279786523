import request from '../request'

//预算列表
export const budget_index_request = p => {
    return request({
        method:'GET',
        url:'budget/index',
        params: p
    })
}

//新增预算
export const budget_add_request = d => {
    return request({
        method:'POST',
        url:'budget/add',
        data: d
    })
}

//删除预算
export const budget_del_request = id => {
    return request({
        method:'DELETE',
        url:'budget/del',
        data: {
            id: id
        }
    })
}

//修改预算
export const budget_edit_request = d => {
    return request({
        method:'PUT',
        url:'budget/edit',
        data: {
            id: d.id,
            with_enterprise_id: d.with_enterprise_id,
            year: d.year,
            quarter: d.quarter,
            money: d.money
        }
    })
}

//预算详情
export const budget_details_request = id => {
    return request({
        method:'GET',
        url:'budget/details',
        params: {
            id
        }
    })
}